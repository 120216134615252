@import "../../App.scss";

.css-79ws1d-MuiModal-root,
.css-8ndowl {
  position: fixed;
  // z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 99 !important;
  .css-1wnsr1i {
    border: none !important;
    box-shadow: none !important;
    border-radius: 5px;
    width: 600px;
    padding: 0px 0px 60px 0px !important;
    max-height: 650px;
    overflow-y: scroll;
    @media (max-width: 620px) {
      width: 340px !important;
    }
    @media (max-width: 340px) {
      width: 300px !important;
    }
    .head {
      padding: 12px 15px;
      width: 100%;
      background: $main-color;
      color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-bottom: 30px;
      .title-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          font-size: 15px;
          color: #eee;
        }
      }
    }
    .modal-iamge {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 150px;
        height: 150px;
        border-radius: 5px;
        margin: auto;
      }
    }
    .img-title {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-weight: bold;
        font-size: 22px;
        columns: #555;
      }
    }
    .button-list {
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 15px;
      .btn-mail {
        background-color: #fff;
        border: 1px solid rgba(20, 29, 70, 1);
        transition: all 0.3s ease;
      }
      .btn-mail:hover {
        background-color: rgba(20, 29, 70, 1);
        color: #fff;
      }
      .btn-phone {
        background-color: #fff;
        border: 1px solid #dd6511;
      }
      .btn-phone:hover {
        background-color: #dd6511;
        color: #fff;
      }
    }
    .details {
      display: flex;
      justify-content: space-evenly;
      align-content: center;
      gap: 15px;
      .addres,
      .role {
        font-size: 18px;
        color: #222;
        font-weight: bold;
        span {
          color: #999;
          font-weight: 600;
          margin-left: 10px;
        }
      }
    }
  }
}
.multi-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 10px;
  cursor: pointer;
  .show-check {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    width: 150px;
    box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
    background-color: #fff;
    z-index: 999;
    position: absolute;
    padding: 10px 0;
    left: -117px;
    border-radius: 8px;
    top: 20px;
    span {
      padding: 2px 10px;
      width: 100%;
      cursor: pointer;
      transition: all 0.3 ease;
      color: #000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-size: 14px;
    }
    :hover {
      background-color: gray;
      color: #fff;
    }
  }
}

.waning {
  padding: 0 10px;
  p {
    width: 80%;
  }
  .btnleist-delte {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    .btn-n {
      background-color: gray;
      color: #fff;
      border: none;
      padding: 8px 30px;
    }
    .btn-y {
      background-color: rgba(20, 29, 70, 1);
      color: #fff;
      border: none;
      padding: 8px 30px;
    }
  }
}
