.paid-amount {
  span {
    background-color: rgb(39, 105, 39);
    padding: 3px 8px;
    color: #fff;
    border-radius: 8px;
    min-width: 100px;
  }
}
.rem-amount {
  span {
    background-color: crimson;
    padding: 3px 8px;
    color: #fff;
    border-radius: 8px;
    min-width: 100px;
  }
}
.total-amount {
  span {
    background-color: rgb(104, 104, 13);
    padding: 3px 8px;
    color: #fff;
    border-radius: 8px;
    min-width: 100px;
  }
}
