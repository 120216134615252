@import '../../App.scss' ;
.action-icon{
    .list-icons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .icon-fav{
            cursor: pointer;
            svg{
                color: rgb(235, 53, 53);
            }
        }
        .icon-view{
            cursor: pointer;
            svg{
                color: rgb(72, 72, 203);
            }
        }
        .icon-edit{
            cursor: pointer;
            svg{
                color: $main-color;
            }
        }
        .icon-delete{
            cursor: pointer;
            svg{
                color: crimson;
            }
        }
    }
}