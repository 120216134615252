.single-appo {
  padding: 20px 0px !important;
  .top-detaisl {
    margin-top: 20px !important;
  }
  .box-wrap-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
    border-radius: 8px;
    padding: 10px;
    gap: 5px;
    i,
    svg {
      color: #2cd8ae;
    }
    .dit {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      span {
        color: gray;
        font-weight: 600;
      }
      p {
        margin: 0;
        color: #666;
      }
    }
  }
  .wraper-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
