@import "../../App";
.big,
.big2 {
  transition: all 0.3s ease-in-out;
  height: 100vh !important;
  overflow-y: scroll !important;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  position: sticky;
  top: 5px;
  box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
  min-height: 100vh;
  background-color: #fff;
  height: 100vh;
  z-index: 9;
  @media (max-width: 1200px) {
    position: absolute;
    width: 290px;
    left: -290px;
    top: 65px;
  }

  .menu-list {
    opacity: 0; /* Initially, hide the menu */
    max-height: 0; /* Hide content */
    overflow: hidden;
    transition: opacity 0.3s ease-in-out; /* Add transition effect */
  }

  /* When the menu is shown, adjust the opacity and max-height properties */
  .menu-list.active {
    opacity: 1;
    max-height: 100%;
    transition: opacity 0.3s ease-in-out; /* Add transition effect */
  }
  .logo {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    margin-top: -3px;
    border-bottom: 1px solid rgba(151, 243, 221, 0.406);
    img {
      width: 50%;
      height: 100%;
      padding-bottom: 5px;
      margin-left: 8px;
    }
  }
  .img-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .list {
    width: 100%;
    img {
      width: fit-content;
      height: 100px;
      margin-bottom: 0;
      margin-top: -20px;
      border-bottom: 1px solid lightgray;
    }
    h6 {
      color: rgb(187, 187, 186);
      font-weight: 700;
      font-size: 15px;
    }
    ul {
      padding: 0 6px;
      margin-bottom: 0 !important;
      a,
      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5px;
        border-radius: 8px;
        padding: 5px;
        gap: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        cursor: pointer;
        color: #444;
        text-decoration: none;
        .icon-s {
          color: $main-color;
          margin-right: 5px;
        }
      }
      a.active,
      div.active,
      a:hover,
      div:hover {
        background-color: rgba(151, 243, 221, 0.406);
        color: gray;
      }
    }
    .themesfather {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-left: 30px;
      .theme {
        width: 20px;
        height: 20px;
        border: 1px solid rgba(151, 243, 221, 0.406);
        cursor: pointer;
        border-radius: 5px;
      }
      .white {
        background-color: rgb(226, 240, 240);
      }
      .dark {
        background-color: #333;
      }
    }
  }
}
.big2 {
  transition: all 0.3s ease-in-out;
  @media (max-width: 1200px) {
    position: absolute;
    width: 290px;
    left: 6px;
    top: 65px;
  }
}
::-webkit-scrollbar {
  width: 3px !important;
}

::-webkit-scrollbar-track {
  background-color: #fff !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(219, 212, 212, 0.724) !important;
}
